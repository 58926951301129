import React, {useEffect} from "react"
import PrivateRoute from "../../router/PrivateRoute";
import github from "../../backend/github";
import Loader from "../../components/Core/Loader";
import {navigate} from "gatsby";

const AuthorizePage = () => {
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const isApp = urlParams.get("app");
    const setupAction = urlParams.get("setup_action");

    if (isApp || setupAction === "install") {
      navigate('/github/app/authorize' + queryString);

      return;
    }

    const code = urlParams.get("code");
    const nonce = urlParams.get("state");

    if (!code || !nonce) {
      return;
    }

    github.authorize(code, nonce)
      .then(({data}) => {
        const {url, jwt} = data;

        if (typeof url === "undefined") {
          alert("Error! Please try again.");
        } else {
          localStorage.setItem('token', jwt);

          window.location.href = data.url;
        }
      });
  }, []);

  return (
    <Loader/>
  );
};

function authorize(props) {
  return <PrivateRoute component={AuthorizePage} {...props}/>
}

export default authorize;
